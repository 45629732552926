import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { WPublicWeddingView } from '@zola/svc-web-api-ts-client';

type PublicV2MetadataProps = {
  wedding: WPublicWeddingView;
  pageType?: string;
  metaImageUrl?: string;
};

const PublicV2Metadata = ({
  wedding,
  metaImageUrl,
  pageType,
}: PublicV2MetadataProps): JSX.Element => {
  const router = useRouter();
  const { preview_type } = router?.query || {};
  const viewportContent =
    (preview_type as string | undefined)?.toUpperCase() === 'DESKTOP'
      ? 'width=1200'
      : 'width=device-width, initial-scale=1.0';

  const pageTitle = `${wedding.owner_first_name} ${wedding.owner_last_name} and ${wedding.partner_first_name} ${wedding.partner_last_name}'s Wedding Website`;
  const fallbackImg =
    'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-heart-black-with-padding_1200x630.png';
  const metaImageUrlFinal = metaImageUrl || fallbackImg;
  const notHomeOrPasscodePage =
    pageType !== 'home' && pageType !== 'passcode' && pageType !== undefined;

  const shouldNotIndex = !wedding.enable_search_engine || notHomeOrPasscodePage;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta
        name="description"
        content={`The wedding website of ${wedding.owner_first_name} ${wedding.owner_last_name} and ${wedding.partner_first_name} ${wedding.partner_last_name}`}
      />
      <meta
        name="keywords"
        content={`wedding website, ${wedding.owner_first_name} ${wedding.owner_last_name}, ${wedding.partner_first_name} ${wedding.partner_last_name}, ${wedding.owner_first_name} and ${wedding.partner_first_name}`}
      />
      <meta name="viewport" content={viewportContent} />
      {shouldNotIndex && <meta name="robots" content="noindex" />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:image" content={metaImageUrlFinal} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={pageTitle} />
      <meta name="twitter:image" property="twitter:image" content={metaImageUrlFinal} />
      <link href={`https://www.zola.com/wedding/${wedding.slug}`} rel="canonical" />
    </Head>
  );
};

export default PublicV2Metadata;
